var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-card',{staticClass:"card-developer-meetup",attrs:{"no-body":""}},[_c('div',{staticClass:"text-center"},[_c('br'),_c('b-img',{attrs:{"src":"https://apvnsa.com/wp-content/uploads/2021/07/logo-apvn.png","alt":"APVN","height":"100%"}}),_c('br')],1),_c('b-card-body',[_c('b-card-title',{staticClass:"mb-2 text-center"},[_vm._v(" Reporte de Falla de "),_c('br'),_vm._v("Alumbrado Público de Villa Nueva ")]),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Por favor ingresa todos los campos requeridos ")]),_c('div',{staticClass:"meetup-header align-items-center"},[_c('div',{staticClass:"meetup-header align-items-center"},[_c('b-form',{style:(_vm.getWidthForm()),on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre:","label-for":"vi-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-name","state":errors.length > 0 ? false:null,"placeholder":"Nombre Reporta"},model:{value:(_vm.ticket.usuarioReporta.nombre),callback:function ($$v) {_vm.$set(_vm.ticket.usuarioReporta, "nombre", $$v)},expression:"ticket.usuarioReporta.nombre"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Dirección:","label-for":"vi-first-name"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MapPinIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-direccion","state":errors.length > 0 ? false:null,"placeholder":"Dirección"},model:{value:(_vm.ticket.direccion),callback:function ($$v) {_vm.$set(_vm.ticket, "direccion", $$v)},expression:"ticket.direccion"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Comunidad / Localidad:","label-for":"vi-first-name"}},[_c('validation-provider',{attrs:{"name":"Comunidad / Localidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"zname","state":errors.length > 0 ? false:null,"options":_vm.localidades},model:{value:(_vm.ticket.localidad),callback:function ($$v) {_vm.$set(_vm.ticket, "localidad", $$v)},expression:"ticket.localidad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Teléfono","label-for":"vi-mobile"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required|digits:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SmartphoneIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-mobile","state":_vm.maxLengthTel && (errors.length > 0 ? false:null),"type":"number","placeholder":"55555555","maxlength":"8","max-length":"8"},model:{value:(_vm.ticket.usuarioReporta.telefono),callback:function ($$v) {_vm.$set(_vm.ticket.usuarioReporta, "telefono", _vm._n($$v))},expression:"ticket.usuarioReporta.telefono"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Correo","label-for":"vi-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-email","state":errors.length > 0 ? false:null,"type":"email","placeholder":"correo@electronico.com"},model:{value:(_vm.ticket.usuarioReporta.email),callback:function ($$v) {_vm.$set(_vm.ticket.usuarioReporta, "email", $$v)},expression:"ticket.usuarioReporta.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Matrícula Poste","label-for":"vi-password"}},[_c('validation-provider',{attrs:{"name":"Matrícula Poste","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CloudLightningIcon"}})],1),_c('b-form-input',{attrs:{"id":"vi-matricula","state":errors.length > 0 ? false:null,"placeholder":"APV123"},model:{value:(_vm.ticket.matricula),callback:function ($$v) {_vm.$set(_vm.ticket, "matricula", $$v)},expression:"ticket.matricula"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Tipo de Falla:","label-for":"vi-first-name"}},[_c('validation-provider',{attrs:{"name":"Tipo de Falla","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"nombre","state":errors.length > 0 ? false:null,"options":_vm.fallas},model:{value:(_vm.ticket.falla),callback:function ($$v) {_vm.$set(_vm.ticket, "falla", $$v)},expression:"ticket.falla"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"labels"},[_vm._v(" Foto de la Falla ")]),_c('imagen',{key:'ticket-web',attrs:{"leyenda":'Ticket Werb'},on:{"cargar-file":_vm.imagenWeb}}),_c('br')],1),_c('b-col',{attrs:{"cols":"12"}},[_c('center',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Ingresar Falla ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":_vm.reset}},[_vm._v(" Limpiar Pantalla ")])],1)],1)],1)],1)],1)]),_c('b-card-text',{staticClass:"mb-0 text-center text-primary"},[_vm._v(" ** Recuerda tener activada tu geolocalización para obtener la referencia de la falla ** ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }