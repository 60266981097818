<template>
  <b-overlay
    :show="show"
  >
    <validation-observer ref="simpleRules">
      <b-card
        no-body
        class="card-developer-meetup"
      >
        <div class="text-center">
          <br>
          <b-img
            src="https://apvnsa.com/wp-content/uploads/2021/07/logo-apvn.png"
            alt="APVN"
            height="100%"
          />
          <br>
        </div>
        <b-card-body>
          <b-card-title class="mb-2 text-center">
            Reporte de Falla de <br>Alumbrado Público de Villa Nueva
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Por favor ingresa todos los campos requeridos
          </b-card-text>
          <!-- Form Datos -->
          <div class="meetup-header align-items-center">
            <div class="meetup-header align-items-center">
              <b-form
                :style="getWidthForm()"
                @submit.prevent
              >
                <b-row class="align-items-center">
                  <!-- first name -->
                  <b-col cols="12">
                    <b-form-group
                      label="Nombre:"
                      label-for="vi-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nombre"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-name"
                            v-model="ticket.usuarioReporta.nombre"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Nombre Reporta"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- direccion -->
                  <b-col cols="12">
                    <b-form-group
                      label="Dirección:"
                      label-for="vi-first-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Dirección"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapPinIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-direccion"
                            v-model="ticket.direccion"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Dirección"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                  <!-- comunidad / localidad -->
                  <b-col cols="12">
                    <b-form-group
                      label="Comunidad / Localidad:"
                      label-for="vi-first-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Comunidad / Localidad"
                        rules="required"
                      >
                        <v-select
                          v-model="ticket.localidad"
                          label="zname"
                          :state="errors.length > 0 ? false:null"
                          :options="localidades"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- mobile -->
                  <b-col cols="12">
                    <b-form-group
                      label="Teléfono"
                      label-for="vi-mobile"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Teléfono"
                        rules="required|digits:8"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="SmartphoneIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-mobile"
                            v-model.number="ticket.usuarioReporta.telefono"
                            :state="maxLengthTel && (errors.length > 0 ? false:null)"
                            type="number"
                            placeholder="55555555"
                            maxlength="8"
                            max-length="8"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- email -->
                  <b-col cols="12">
                    <b-form-group
                      label="Correo"
                      label-for="vi-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-email"
                            v-model="ticket.usuarioReporta.email"
                            :state="errors.length > 0 ? false:null"
                            type="email"
                            placeholder="correo@electronico.com"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Matricula Poste -->
                  <b-col cols="12">
                    <b-form-group
                      label="Matrícula Poste"
                      label-for="vi-password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Matrícula Poste"
                        rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="CloudLightningIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="vi-matricula"
                            v-model="ticket.matricula"
                            :state="errors.length > 0 ? false:null"
                            placeholder="APV123"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Tipo Falla -->
                  <b-col cols="12">
                    <b-form-group
                      label="Tipo de Falla:"
                      label-for="vi-first-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de Falla"
                        rules="required"
                      >
                        <v-select
                          v-model="ticket.falla"
                          label="nombre"
                          :state="errors.length > 0 ? false:null"
                          :options="fallas"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Imagen -->
                  <b-col cols="12">
                    <span class="labels">
                      Foto de la Falla
                    </span>
                    <imagen
                      :key="'ticket-web'"
                      :leyenda="'Ticket Werb'"
                      @cargar-file="imagenWeb"
                    />
                    <br>
                  </b-col>
                  <!-- reset and submit -->
                  <b-col cols="12">
                    <center>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click.prevent="validationForm"
                      >
                        Ingresar Falla
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="reset"
                      >
                        Limpiar Pantalla
                      </b-button>
                    </center>
                  </b-col>
                </b-row>
              </b-form>
            </div>

          </div>

          <b-card-text class="mb-0 text-center text-primary">
            ** Recuerda tener activada tu geolocalización para obtener la referencia de la falla **
          </b-card-text>
        </b-card-body>
      </b-card>
    </validation-observer>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, VBTooltip,
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BInputGroup, BInputGroupPrepend,
  BOverlay,
} from 'bootstrap-vue'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { getLocalidades } from '@/utils/localizacion'
import { getlistado } from '@/utils/catalogos'
import {
  required, email, length, digits,
} from '@validations'
import { updateCreateTicket } from '@/utils/tickets'
import { sendFile } from '@/utils/files'
/* eslint-disable global-require */
export default {
  components: {
    Imagen,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    vSelect,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      digits,
      email,
      length,
      imagen: null,
      localidades: [],
      fallas: [],
      localization: null,
      ticket: {
        fechaCreacion: new Date(),
        companyId: '6275fea8316ab6c2866981f7',
        idInspeccion: '',
        statusGUI: true,
        falla: null,
        comentarios: 'Ingreso WEB',
        estado: 'CONFIRMACION',
        imagenes: [],
        seguimiento: [],
        canal: 'WEB',
        direccion: '',
        localidad: null,
        matricula: '',
        usuarioReporta: {
          nombre: '',
          telefono: '',
          email: '',
        },
        usuario: JSON.parse(localStorage.getItem('userData')),
      },
    }
  },
  computed: {
    maxLengthTel() {
      return this.ticket.usuarioReporta.telefono.length > 8
    },
  },
  async beforeMount() {
    this.localidades = await getLocalidades(undefined, 15)
    this.fallas = await getlistado(7, 'Fallas', false, this.usuario)
    if (this.fallas === null) this.fallas = []
  },
  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    imagenWeb(file) {
      this.imagen = file
    },
    isMobile() {
      if (window.screen.width <= 760) {
        return true
      }
      return false
    },
    getWidthForm() {
      if (this.isMobile()) return 'max-width:100%;'
      return 'max-width:450px;'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.guardarInspeccion()
        }
      })
    },
    async guardarInspeccion() {
      if (this.imagen === '') {
        this.error('Debe ingresar la fotografía!')
        return
      }

      if (this.localization === null || this.localization === undefined) {
        this.error('Error obteniendo geolocalización, intente nuevamente!')
        this.show = false
        return
      }

      this.show = true
      delete this.ticket.idInspeccion
      const saveTicket = await updateCreateTicket(this.ticket, 2)
      if (saveTicket !== null) {
        this.ticket.id = saveTicket.id
        console.log(saveTicket)
        const urlBucket = `tickets/${saveTicket.id}`
        const saveImagenPoste = await sendFile(this.imagen, 'images', urlBucket)
        if (saveImagenPoste !== null) {
          saveImagenPoste.data.isMobile = window.screen.width <= 760
          saveImagenPoste.data.localization = this.localization
          saveImagenPoste.data.tipo = 'Imagen Falla'
          saveImagenPoste.data.fechaHora = new Date()
          this.ticket.imagenes.push(saveImagenPoste.data)
        }
        await updateCreateTicket(this.ticket, 1)
        this.success('Ticket', 'Falla Almacenada Correctamente!!')
        setTimeout(this.reset(), 6000)
      } else {
        this.error('Error almacenando la Falla, intente nuevamente!')
      }
      this.show = false
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    reset() {
      window.location.reload()
    },
  },
}
</script>
<style scoped>
form {
  margin: 0 auto;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
